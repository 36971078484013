.kivvi_sidebar {
    container-type: inline-size;
    container-name: kivvi-sidebar;
    
    text-align: left;
    h2 {
        font-size: 38px;
        margin-block-end: 0;
    }
    a {
        text-decoration: none;
    }
    ul {
        margin-block-start: 0.5rem;
    }
}
