.columns-two {
    display: flex;
}

.kivvi-columns {    
    display: flex;
    gap: var(--space-medium);
    width: 100%;
    justify-content: space-between;
    flex-wrap: wrap;
    > * {
        min-width: var(--kivvi-single-item-width);
    }
    &.equal-width {
        > * {
            flex: 1;
            flex-wrap: wrap;
            margin: auto;
        }
    }
    &.cols-1-2 {
        align-items: flex-start;
        gap: var(--space-largest);
        > * {
            flex: 1;
            flex-wrap: wrap;
            
            &:last-of-type {
                flex: 2;
            }
        }
    }
    &.cols-2-1 {
        > * {
            flex: 1;
            flex-wrap: wrap;
            
            &:first-of-type {
                flex: 2;
            }
        }
    }
    &.cols-60-40 {
        > * {
            flex: 40;
            flex-wrap: wrap;
            
            &:first-of-type {
                flex: 60;
            }
        }
    }
}

.hanging-indent {
    padding-left: 22px;
    text-indent: -22px;
}
