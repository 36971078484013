.fadein {
    opacity: 0;
    transition: opacity 4s, transform 1s;
    transform: translateY(100px);
}
.fadein.active {
    opacity: 1;
    transform: translateY(0);
}
@media (prefers-reduced-motion: reduce) {
    .fadein {
        opacity: 1;
        transform: translateY(0);
    }
}
