:root {
    --kivvi-dark-section-background: #000000;
    --kivvi-dark-section-text: #ffffff;
}

.kivvi-dark-section {
    .kivvi_section_content,
    &.full-width {
        background-color: var(--kivvi-dark-section-background);
        color: var(--kivvi-dark-section-text);
    }
}
.kivvi-dark-section :is(h1, h2, h3, h4, h5, h6, p, span, li) {
    color: var(--kivvi-dark-section-text);
}
