

:is(h1, h2, h3, h4, h5) {
    
    letter-spacing: 1.5px;
    line-height: 1.2;
    hyphens: manual;
    
}
h1 {
    font-size: var(--kivvi-h1-font-size);
    margin-block: var(--space-larger);
    font-family: var(--kivvi-h1-font-family);     
    color: var(--kivvi-h1-font-color);
    text-align: var(--kivvi-h1-text-align);
}
h2 {
    font-size: var(--kivvi-h2-font-size);
    margin-block: var(--space-medium);    
    font-family: var(--kivvi-h2-font-family);     
    color: var(--kivvi-h2-font-color);
    text-align: var(--kivvi-h2-text-align);
}
h3 {
    font-size: var(--kivvi-h3-font-size);
    margin-block: var(--space-small);  
    font-family: var(--kivvi-h3-font-family);  
    color: var(--kivvi-h3-font-color);    
    text-align: var(--kivvi-h3-text-align);
}
h4 {
    font-size: var(--kivvi-h4-font-size);
    margin-block: var(--space-small);
    font-family: var(--kivvi-h4-font-family);        
    color: var(--kivvi-h4-font-color);
    text-align: var(--kivvi-h4-text-align);
}
