:root {
    --kivvi-base-font-size: 1.25rem; // 20px
    /* CLAMP CALCULATED AS PER HERE : https://chrisburnell.com/clamp-calculator/ using 500 & 1000 as min and max widths */
    --kivvi-h1-font-size: clamp(2.5rem, 1rem + 4.8vw, 4rem);
    --kivvi-h2-font-size: clamp(2rem, 1rem + 3.2vw, 3rem);
    --kivvi-h3-font-size: clamp(1.5rem, 0.75rem + 2.4vw, 2.25rem);
    --kivvi-h4-font-size: clamp(1.25rem, 0.75rem + 1.6vw, 1.75rem);
    



/*

    --kivvi-min-font-size: 1rem; // 16px
    --kivvi-max-font-size: 4rem;
    
    /* MAX FONT SIZES USING GOLDEN RATIO *//*
    --kivvi-font-size-l4h: min(var(--kivvi-base-font-size) * 1.1618, var(--kivvi-max-font-size));  // 29.045px
    --kivvi-font-size-l3h: min(var(--kivvi-base-font-size) * 1.1618 * 1.1618 * 1.1618, var(--kivvi-max-font-size)); // 39.204px
    --kivvi-font-size-l2h: min(var(--kivvi-base-font-size) * 1.1618 * 1.1618 * 1.1618 * 1.1618 * 1.1618, var(--kivvi-max-font-size)); // 52.9172
    --kivvi-font-size-l1h: min(var(--kivvi-base-font-size) * 1.1618 * 1.1618 * 1.1618 * 1.1618 * 1.1618 * 1.1618 * 1.1618, var(--kivvi-max-font-size)); // 71.4265

    /* MIN FONT SIZES - DEFAULT TO DIVIDE BY 2 *//*
    --kivvi-font-size-min-divider: 2;
    --kivvi-font-size-l4l: max(var(--kivvi-font-size-l4h) / var(--kivvi-font-size-min-divider), var(--kivvi-min-font-size)); // 16px is min
    --kivvi-font-size-l3l: max(var(--kivvi-font-size-l3h) / var(--kivvi-font-size-min-divider), var(--kivvi-min-font-size)); // 19.6022px 
    --kivvi-font-size-l2l: max(var(--kivvi-font-size-l2h) / var(--kivvi-font-size-min-divider), var(--kivvi-min-font-size)); // 26.4586px
    --kivvi-font-size-l1l: max(var(--kivvi-font-size-l1h) / var(--kivvi-font-size-min-divider), var(--kivvi-min-font-size)); // 35.7133px

    /* CLAMP CALCULATED AS PER HERE : https://chrisburnell.com/clamp-calculator/ *//*
    --kivvi-font-size-l4c: 0.812rem + 1.003vw;
    --kivvi-font-size-l3c: 0.942rem + 1.508vw;
    --kivvi-font-size-l2c: 1.125rem + 2.125vw;
    --kivvi-font-size-l1c: 1.717rem + 2.747vw;

    --kivvi-font-size-l4: clamp(var(--kivvi-font-size-l4l), var(--kivvi-font-size-l4c), var(--kivvi-font-size-l4h));
    --kivvi-font-size-l3: clamp(var(--kivvi-font-size-l3l), var(--kivvi-font-size-l3c), var(--kivvi-font-size-l3h));
    --kivvi-font-size-l2: clamp(var(--kivvi-font-size-l2l), var(--kivvi-font-size-l2c), var(--kivvi-font-size-l2h));
    --kivvi-font-size-l1: clamp(var(--kivvi-font-size-l1l), var(--kivvi-font-size-l1c), var(--kivvi-font-size-l1h));
    */
    
}