.kivvi-logogallery-items {
    display: flex;
    flex-wrap: wrap;
    gap: var(--space-medium);
    align-items: center;
    justify-content: space-around;

}
.kivvi-logogallery-item {
    flex: 1;
    min-width: 150px;
    max-width: 250px;
    img {
        object-fit: contain;
    }
}