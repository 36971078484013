:root {
    --kivvi-button-background: transparent;
    --kivvi-button-color: black;
    --kivvi-button-padding: 0.5rem var(--space-button-inline);
    --kivvi-button-radius: 10px;
    --kivvi-button-border-color: black;
    --kivvi-button-border: 1px solid var(--kivvi-button-border-color);
    --kivvi-button-alternate-border-color: black;
    --kivvi-button-alternate-border: 1px solid var(--kivvi-button-alternate-border-color);
    --kivvi-button-hover-color: white;
    --kivvi-button-hover-background: black;
    --kivvi-button-transition: 0.5s all ease-in-out;
    --kivvi-button-alternate-color: white;
    --kivvi-button-alternate-background: black;
    --kivvi-button-alternate-hover-color: black;
    --kivvi-button-alternate-hover-background: white;
}

button,
.button, .woocommerce:where(body:not(.woocommerce-block-theme-has-button-styles)) a.button {
    cursor: pointer;
    background-color: var(--kivvi-button-background);
    color: var(--kivvi-button-color);
    padding: var(--kivvi-button-padding);
    border-radius: var(--kivvi-button-radius);
    border: var(--kivvi-button-border);
    transition: var(--kivvi-button-transition);
    text-decoration: none;
    font-weight: normal;
    display: inline-block;
    line-height: 1.8;
    a {
        color: var(--kivvi-button-color);
        text-decoration: none;
        transition: var(--kivvi-button-transition);
    }
    &:hover,
    a:hover,
    &:hover a {
        color: var(--kivvi-button-hover-color);
        background-color: var(--kivvi-button-hover-background);
    }
    &.button--alternate {
        border: var(--kivvi-button-alternate-border);
    }
    &.button--alternate,
    &.button--alternate a {
        background-color: var(--kivvi-button-alternate-background);
        color: var(--kivvi-button-alternate-color);
        transition: var(--kivvi-button-transition);
        
        &:hover,
        a:hover,
        &:hover a {
            color: var(--kivvi-button-alternate-hover-color);
            background-color: var(--kivvi-button-alternate-hover-background);
        }
    }
}

a.button + a.button {
    margin-block-start: var(--space-default);
}
a.button:has(+ a.button) {
    margin-inline-end: var(--space-default);
}
