:root {
    --kivvi-accordion-details-radius: 0;
    --kivvi-accordion-details-margin: var(--space-default) auto;
    --kivvi-accordion-summary-padding: var(--space-default);
    --kivvi-accordion-summary-background: none;
    --kivvi-accordion-summary-font-size: var(--kivvi-h4-font-size);
    --kivvi-accordion-border: 1px solid black;
    --kivvi-accordion-border-color: black;

    --kivvi-accordion-disembodied-highlight-color: var(--kivvi-link-color);
    --kivvi-accordion-disembodied-highlight-color: red;
    --kivvi-accordion-disembodied-tab-margin: 20px;
    // --kivvi-accordion-disembodied-tab-margin: 220px;
    --kivvi-accordion-current-tab-offset: var(--kivvi-accordion-disembodied-tab-margin);
}

.kivvi-accordion {
    details {
        box-sizing: border-box;
        margin: var(--kivvi-accordion-details-margin);
        border: var(--kivvi-accordion-border);
        border-radius: var(--kivvi-accordion-details-radius);
        summary {
            background: var(--kivvi-accordion-summary-background);
            color: var(--kivvi-color-dark);

            font-size: var(--kivvi-accordion-summary-font-size);
            text-align: left;
            background: unset;
            cursor: pointer;
            padding: var(--kivvi-accordion-summary-padding);
            position: relative;
            &:focus {
                border-color: black;
            }
            &::marker {
                display: none;
                content: "";
            }
            &:before {
                content: "+";
                position: absolute;
                right: 20px;
                top: 0;
                font-size: 40px;
            }
        }
        &[open] summary:before {
            content: "-";
        }
        .kivvi-accordion-content {
            border: none;
            padding: 0 5%;
        }
    }
}
details > summary::-webkit-details-marker {
    display: none;
    content: "";
}

.kivvi-accordion-disembodied {
    --accordion-highlight-background: gray;
    display: flex;
    width: 100%;
    justify-content: space-around;
    gap: 4rem;
}

.kivvi-accordion-disembodied {
    display: none;
    margin-block-start: 3rem;
}
.kivvi-accordion-disembodied-tabs {
    min-width: 400px;
    max-width: 100%;
    border-radius: var(--kivvi-accordion-details-radius);
    ul {
        list-style-type: none;
        padding-left: 0;
        display: flex;
        gap: 2rem;
        flex-direction: column;
        position: sticky;
        top: var(--kivvi-accordion-current-tab-offset);
        transition: all 1s;
    }
    li {
        padding: 1rem;
        border: 1px solid black;
        border-radius: 20px;

        &.active {
            border-color: var(--kivvi-accordion-disembodied-highlight-color);
        }
    }
}

.kivvi-accordion-disembodied-content {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    article {
        border: 1px solid gray;
        border-radius: 20px;
        padding: 2rem;
        &.active {
            border-color: var(--kivvi-accordion-disembodied-highlight-color);
        }
    }
}

.kivvi-accordion.layout-disembodied {
    container-type: inline-size;
    container-name: disembodied;
    // @media screen and (min-width: 1000px) {
    // @container  (min-width: 1000px) {
    //     .kivvi-accordion-disembodied {
    //         display: flex;
    //     }
    //     details {
    //         display: none;
    //     }
    // }
}
@container disembodied (min-width: 1000px) {
    .kivvi-accordion-disembodied {
        display: flex;
    }
    details {
        display: none;
    }
}
