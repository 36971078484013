.typing {
    // overflow: hidden; /* Ensures the content is not revealed until the animation */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    position: relative;
    span {
        line-height: 1.5;
        display: inline-block;
        transition: opacity 0.3s;
    }
    span:not(.active):not(.activestop):not(.start) {
        position: absolute;
        opacity: 0;
        pointer-events: none;
        left: 0;
        top: 0;
    }
}

.typing span.active {
    opacity: 1;
    animation: scrollinout 4s forwards;
    line-height: 1.5;
}

.typing span.activestop {
    opacity: 1;
    animation: scrollin 2s forwards;
    line-height: 1.5;
}
.typing span.starting {
    opacity: 1;
    animation: scrollout 3s forwards;
    line-height: 1.5;
}

@media (prefers-reduced-motion: reduce) {
    .typing span {
        animation: none;
        &.active {
            animation: none;
        }
        &.activestop {
            animation: none;
        }
        &.starting {
            animation: none;
        }
    }
}

@keyframes scrollin {
    0% {
        opacity: 0;
        clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
    }
    80% {
        opacity: 1;
    }
    100% {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
}
@keyframes scrollinout {
    0% {
        opacity: 0;
        clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
    }
    30% {
        opacity: 1;
    }
    40% {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
    60% {
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
    70% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
    }
}
@keyframes scrollout {
    0% {
        opacity: 1;
        clip-path: polygon(0 0, 100% 0%, 100% 100%, 0% 100%);
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        clip-path: polygon(0 0, 0% 0%, 0% 100%, 0% 100%);
    }
}
@keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 100%;
    }
}
