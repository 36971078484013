.gform_wrapper {
    .gform_heading, form {
        max-width: 1000px;
        margin: 0 auto;
        text-align: left;
        
    }
    
    
}
.gform_wrapper.gravity-theme .field_sublabel_above .gfield_description {
    margin-top: 0 !important;
}