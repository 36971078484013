.kivvi-container-with-sidebar {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    @media screen and (max-width: 1280px) {
        display: block;
    }
    .kivvi-post-content {
        container-name: kivvi-post-content;
        container-type: inline-size;
        flex: 2;
        
    }
    .kivvi_sidebar {
        flex: 1;        
    }
    .blogroll {
        display: block;
    }
    .post-excerpt {
        display: none;
    }
    
}