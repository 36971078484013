:root {
    --kivvi-carousel-controls-height: 100px;
    --kivvi-carousel-dotnav-height: 15px;
    --kivvi-carousel-arrow-color: grey;
    --kivvi-carousel-arrow-color-hover: black;
}

.kivvi-carousel {
    position: relative;
    height: 100%;
    overflow-x: hidden;
    &[data-kivvi-carousel-transition="fade"] {
        .kivvi-carousel-item {
            z-index: 0;
            &.active-carousel {
                z-index: 1;
                opacity: 1;
            }
            &.transitionOut,
            &.transitionOutBack {
                opacity: 0;
                z-index: 0;
            }
        }
    }
    &[data-kivvi-carousel-transition="slide"] {
        .kivvi-carousel-item {
            transform: translateX(50%);
            pointer-events: none;
            &.active-carousel {
                transform: translateX(0);
                opacity: 1;
                pointer-events: all;
            }
            &.transitionOut {
                transform: translateX(-100%);
                opacity: 0;
            }
            &.transitionOutBack {
                transform: translateX(100%);
                opacity: 0;
            }
            &.sliderPrev {
                transform: translateX(-100%);
                transition: none;
            }
        }
    }
}

.kivvi-carousel-item {
    opacity: 0;
    transition:
        opacity 0.3s,
        transform 1s;
    height: 100%;
    position: absolute;
    width: 100%;
}

.kivvi-carousel[data-kivvi-carousel-show="2"] {
    display: flex !important;
    gap: var(--space-default);

    > * {
        flex: 1;
    }
    .kivvi-carousel-item {
        width: calc(50% - var(--space-default) / 2);
        &.active-carousel:nth-of-type(2n + 1) {
            right: 0;
        }
    }
}

.kivvi-carousel-controls {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 5;
    pointer-events: none;
    top: 0;
    left: 0;
    transition: all 1s;
    .prev,
    .next {
        cursor: pointer;
        height: var(--kivvi-carousel-controls-height);
        position: absolute;
        top: calc(50% - var(--kivvi-carousel-controls-height) / 2);
        left: 0;
        display: flex;
        align-items: center;
        justify-content: center;

        padding: var(--space-default) 0;
        font-size: 75px;
        font-weight: 100;
        a {
            color: var(--kivvi-carousel-arrow-color);
            transition: all 0.3s;
            pointer-events: all;
            text-decoration: none;
            &:hover {
                color: var(--kivvi-carousel-arrow-color-hover);
            }
        }
    }
    .next {
        left: unset;
        right: 0;
    }
}

.navdots {
    display: flex;
    justify-content: center;
    gap: 5px;
}
.navdot {
    display: inline-block;
    border: 1px solid black;
    border-radius: 50%;
    width: 25px;
    height: 25px;
    pointer-events: all;
    &.active {
        background: black;
    }
}
