:root {
    --nav-breakpoint: 1000px;
    --nav-icon-background: #ffffff;
    --nav-icon-background-hover: #777777;
    --nav-drawer-link-color: white;
    --nav-drawer-background: black;
    --nav-drawer-underline-color: white;
    --nav-icon-size: 40px;
    --nav-icon-margin-block: 50px;
    --nav-icon-line-size: 7px;
    --nav-menu-gap: var(--space-default);
}

#masthead {
    
    display: flex;
    justify-content: space-between;
    nav {
        
        font-size: 16px;
        display: flex;
        align-items: center;
        ul.menu {
            list-style: none;
            display: flex;
            gap: var(--nav-menu-gap);
            align-items: center;
            flex-wrap: wrap;
        }
        a {
            text-decoration: none;
        }
        .sub-menu {        
            position: absolute;
            text-align: left;
            z-index: 9999;            
            max-height: 0;
            opacity: 0;
            overflow: hidden;
            pointer-events: none;
            background: unset;
            padding-left: 0;
            li {
                display: block;
            }
        }
    }
        li:hover {
            
            .sub-menu {
                max-height: 1000px;
                pointer-events: all;
                opacity: 1;
                transition: all 0.5s ease-in-out;
                
            }
        
    }
    
}

.site-title {
    a {
        text-decoration: none;
    }
}

#mobile-toggle {
    z-index: 9001;
}

#nav-icon {
    width: var(--nav-icon-size);
    display: none;
    z-index: 9001;
    height: var(--nav-icon-size);
    position: relative;
    margin: var(--nav-icon-margin-block) auto;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    -webkit-transition: 0.5s ease-in-out;
    -moz-transition: 0.5s ease-in-out;
    -o-transition: 0.5s ease-in-out;
    transition: 0.5s ease-in-out;
    cursor: pointer;
}

#nav-icon span {
    display: block;
    position: absolute;
    height: var(--nav-icon-line-size);
    width: 100%;
    background: var(--nav-icon-background);
    background-size: 200% 200%;
    background-position: 0% 0%;

    border-radius: 7px;
    opacity: 1;
    left: 0;
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
    transition: all 0.4s ease-in-out;
}

a #nav-icon:hover span,
a #nav-icon.open span {
    background-color: var(--nav-icon-background-hover);
}

#nav-icon.open:hover span {
    filter: brightness(1.5);
}

#nav-icon span:nth-child(1) {
    top: 0px;
}

#nav-icon span:nth-child(2) {
    top: calc((var(--nav-icon-size) / 2) - (var(--nav-icon-line-size) / 2));
}

#nav-icon span:nth-child(3) {
    bottom: 0;
}

#nav-icon.open span:nth-child(1) {
    top: 18px;
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
}

#nav-icon.open span:nth-child(2) {
    opacity: 0;
    left: -60px;
}

#nav-icon.open span:nth-child(3) {
    top: 18px;
    -webkit-transform: rotate(-135deg);
    -moz-transform: rotate(-135deg);
    -o-transform: rotate(-135deg);
    transform: rotate(-135deg);
}

@media screen and (max-width: 1000px) {
    
    #site-navigation {
        transform: translateX(150%);
        transition: transform 0.5s ease-in-out;
        background: var(--nav-drawer-background);
        
        position: fixed;
        top: 0;
        right: 0;
        width: 100%;
        height: 100%;
        overflow: auto;
        color: var(--nav-drawer-link-color);

        z-index: 9000;

        &.active {
            transform: translateX(0);
        }
        // transform: translateY(100%);
    }
    #nav-icon {
        display: block;
    }

    #masthead {
        nav {
            overflow: auto;
            justify-content: center;
            align-items: flex-start;
            padding-top: 5rem;
            padding-bottom: var(-space-medium);
            display: block;
            width: 100%;
            > div {
                width: 100%;
            }
            ul.menu {
                padding: 0;
                flex-direction: column;
                
                width: 90%;
                max-width: 400px;
                text-align: left;
                margin: auto;
                a {
                    color: var(--kivvi-color-teal);
                    position: relative;
                    display: block;
                    padding-bottom: var(--space-small);
                    &:after {
                        content: "";
                        bottom: 0;
                        left: 0;
                        position: absolute;
                        height: 2px;
                        background: var(--nav-drawer-underline-color);

                        width: calc(90vw - 80px);
                        max-width: 400px;
                    }
                }
                .sub-menu a:after {
                    width: calc(90vw - 120px);
                }
                > li:last-of-type a {
                    border-bottom: none;
                }
            }
            li {
                width: 100%;
                padding-inline: 1.5rem;
            }
            a {
                color: var(--kivvi-color-dark-teal);
            }
            .sub-menu {
                padding-top: var(--nav-menu-gap);
                margin-top: var(--space-small);
                li {
                    position: relative;
                }
            }
        }
    }
}

.nav-links {
    display: flex;
    justify-content: center;
    gap: var(--nav-menu-gap);
}
