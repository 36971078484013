.rotate {
    transition: transform 2s;
    max-width: 90%;
    &.active {
        transform: rotate(var(--animationRotation));
    }
}
@media (prefers-reduced-motion: reduce) {
    .rotate {
        transform: rotate(var(--animationRotation));
    }
}
