:root {
    --kivvi-tab-title-color: black;
    --kivvi-tab-title-text-decoration: none;
    --kivvi-tab-active-title-background: grey;
    --kivvi-tab-active-title-color: white;
    --kivvi-tab-links-bottom-border: black;
    --kivvi-tab-links-bottom-border-height: 1px;
    --kivvi-tabs-padding-left: var(--space-medium);
    --kivvi-tabs-padding-top: var(--space-default);
    --kivvi-tabs-title-gap: var(--space-default);
}
.kivvi-tabs {
    .kivvi-header-text {
        margin-block-end: var(--space-medium);
    }
}
.kivvi-tabs-items {
    --tab-template-cols: 3;
    --tab-template-header: "tab-0 tab-1 tab-2";
    --tab-template-content: "content content content";

    display: grid;
    grid-template-rows: 2;
    grid-template-areas:
        var(--tab-template-header)
        var(--tab-template-content);

    .tab-0 {
        grid-area: tab-0;
    }
    .tab-1 {
        grid-area: tab-1;
    }
    .tab-2 {
        grid-area: tab-2;
    }
    .tab-3 {
        grid-area: tab-3;
    }
    .tab-4 {
        grid-area: tab-4;
    }
    .tab-5 {
        grid-area: tab-5;
    }
    .kivvi-tabs-section {
        grid-area: content;
    }

    /*
    > ul {
        width: 100%;
        gap: var(--kivvi-tabs-title-gap);
        display: flex;
        position: relative;
        justify-content: flex-start;
        flex-wrap: wrap;
        padding-inline-start: var(--kivvi-tabs-padding-left);
        &:after {
            content: "";
            position: absolute;
            bottom: calc(-1 * var(--kivvi-tab-links-bottom-border-height));
            bottom: 0;
            left: var(--kivvi-tabs-padding-left);
            height: var(--kivvi-tab-links-bottom-border-height);
            width: calc(100% - var(--kivvi-tabs-padding-left));
            background: var(--kivvi-tab-links-bottom-border);
            @media screen and (max-width: 768px) {
                left: 0;
                width: 100%;
            }
        }
        @media screen and (max-width: 768px) {
            padding-inline-start: 0;
        }
    }
    

    */
}
.kivvi-tab-title {
    list-style: none;
    position: relative;
}
.kivvi-tab-title-link {
    color: var(--kivvi-tab-title-color);
    text-decoration: var(--kivvi-tab-title-text-decoration);
    // display: inline-flex;
    display: block;
    transition: all 0.3s;
    padding: var(--space-default);
    word-wrap: break-word;
    &[aria-selected] {
        background: var(--kivvi-tab-active-title-background);
        color: var(--kivvi-tab-active-title-color);
    }
}
.kivvi-tabs-section {
    padding-inline-start: var(--kivvi-tabs-padding-left);
    padding-block-start: var(--kivvi-tabs-padding-top);
    opacity: 1;
    transition: all 0.5s;

    @media screen and (max-width: 768px) {
        padding-inline-start: 0;
    }
}

.js .kivvi-tabs-section.inactive {
    opacity: 0;
    transition: all 0.5s;
}

@media screen and (max-width: 1000px) {
    .kivvi-tabs-items {
        display: block;
    }
    .kivvi-tabs-section:not(:first-of-type) {
        // max-height: 30000px;
        max-height: 100vh;

        height: auto;
        overflow: visible;
    }
    .js .kivvi-tabs-section.inactive {
        opacity: 1;
        max-height: 0;
        height: auto;
        overflow: hidden;
        padding: 0;
        margin: 0;
        border: none;
    }
}
