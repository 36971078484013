.kivvi-team-member {
    display: flex;
    flex-direction: column;
    gap: var(--kivvi-spacing-2);
    &.kivvi-team-member-layout-two {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr;
        
    }
    h1 {
        margin: 0;
    }
}
.kivvi-team-member-title {
    grid-column: 1 / 2;
    
}
.kivvi-team-member-bio {
    grid-column: 1 / 2;
    
}
.kivvi-team-member-photo {
    grid-column: 2 / 3;
    
}
.kivvi-team-member-contact {
    grid-column: 2 / 3;

}

a.kivvi-team-social {
    text-decoration: none;
    
    color: var(--kivvi-color-primary);
    &:hover {
        cursor: pointer;
        color: var(--kivvi-color-secondary);
        span:before {
            cursor: pointer;
        }
    }

}