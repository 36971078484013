.woocommerce-page {
    #page {
        background-size: cover;
        text-align: center;
        padding: var(--space-default) 0;
        margin: var(--space-larger) auto;
        position: relative;
    }
    #content {
        position: relative;
        max-width: var(--kivvi-max-content-width);
        margin: auto;
        width: 90%;
        display: flex;
        flex-direction: column;
        gap: var(--space-larger);
    }
}