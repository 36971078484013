// TODO: WHEN :has is supported, use that instead of the class, REMOVE CLASS FROM UTILS.JS
// .section-group.full-width:has(.media-flush),
.section-group.full-width.has-media-flush {
    @media screen and (min-width: 1280px) {
        .kivvi_section {
            padding: 0;
        }
        .kivvi_section_content {
            width: 100vw;
            max-width: unset;
            .kivvi-columns {
                gap: var(--space-largest);
            }
        }
        .kivvi-twoup-media {
            width: 100%;
            img {
                min-width: 100%;
            }
        }
        .kivvi-image-wrapper {
            display: flex;
            overflow: hidden;
        }
        .media-left {
            .kivvi-twoup-content-inner {
                padding-right: var(--kivvi-full-width-padding-left);
            }
        }
        .media-right {
            .kivvi-twoup-content-inner {
                padding-left: var(--kivvi-full-width-padding-left);
            }
        }
    }
}

.kivvi-twoup {
    text-align: center;
    // @media screen and (min-width: 1001px) {
    //     .kivvi-columns {
    //         max-height: 600px;
    //         *:not(img) {
    //             max-height: var(--kivvi-single-item-width-max);
    //         }
    //     }
    // }
    @media screen and (max-width: 1000px) {
        .kivvi-columns {
            flex-direction: column;
            gap: var(--space-default);
        }
        &.media-right {
            .kivvi-twoup-media {
                order: 1;
            }
            .kivvi-twoup-content {
                order: 2;
            }
        }
    }

    p {
        width: unset;
        margin-left: unset;
        margin-right: unset;
    }
    img {
        object-fit: cover;
        max-height: 100%;
    }
}
