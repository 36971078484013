.slideuptext {
    --animationStartTranslateY: 200%;    
    line-height: 1.2;
    overflow: hidden;
    .inner {
        display: inline-block;
        transform: translateY(var(--animationStartTranslateY));
        transition: transform var(--animationSpeed);
        opacity: var(--animationStartOpacity);
    }
    &.active {
        .inner {
            transform: translateY(0);
            opacity: 1;
        }
    }
}

@media (prefers-reduced-motion: reduce) {
    .slideuptext .inner {
        transform: translateY(0);
    }
}

.slideupWord {
    white-space: nowrap;
}

.active .slideup {
    transform: translateY(0);
    transition: all 2s;
    &.delay-1 {
        transition-delay: 0.5s;
    }
    &.delay-2 {
        transition-delay: 1s;
    }
    &.delay-3 {
        transition-delay: 1.5s;
    }
    &.delay-4 {
        transition-delay: 2s;
    }
    &.delay-5 {
        transition-delay: 2.5s;
    }
    &.delay-6 {
        transition-delay: 3s;
    }
    &.delay-7 {
        transition-delay: 3.5s;
    }
    &.delay-8 {
        transition-delay: 4s;
    }
    &.delay-9 {
        transition-delay: 4.5s;
    }
}
.slideup {    
    transform: translateY(var(--animationStartTranslateY));
    transition: all var(--animationSpeed);
    opacity: var(--animationStartOpacity);
    transition-delay: var(--animationDelay);
    &.active {
        transform: translateY(0);
        opacity: 1;
    }
}

@media (prefers-reduced-motion: reduce) {
    .slideup {
        transform: translateY(0);
        transition: none;
    }
    .active .slideup {
        transition: none;
    }
}
