.kivvi-hero {
    h1,
    h2 {
        margin-top: 0;
        margin-bottom: var(--space-small);
    }
    .kivvi-hero-leadin {
        margin-top: var(--space-default);
    }
    > a.button {
        margin-top: var(--space-default);
    }
}
