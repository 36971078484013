img.alignright {
    float: right;
    margin: 0 0 1em 1em;
}
img.alignleft {
    float: left;
    margin: 0 1em 1em 0;
}
img.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}
.alignright {
    float: right;
}
.alignleft {
    float: left;
}
.aligncenter {
    display: block;
    margin-left: auto;
    margin-right: auto;
}

// div :has(> img[class^=wp-image]) {
div[id^="attachment_"] {
    max-width: 100% !important;
    // width: 100% !important;
}

.kivvi-twoup .kivvi-object-fit-cover {
    img {
        object-fit: cover;
    }
}
.kivvi-twoup .kivvi-object-fit-contain {
    img {
        object-fit: contain;
        height: auto;
    }
}
