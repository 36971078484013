:root {
    --kivvi-callout-bar-background-color: var(--kivvi-color-green);
}

.kivvi-callout.bar {    
    transition-property: transform;
    transition-duration: var(--kivvi-modal-transition-length);
    transition-delay: var(--kivvi-modal-transition-delay);
    transform: translateY(-100%);
    height: auto;
    z-index: 999999;
    opacity: 1;
    &[data-position=bottom] {
        transform: translateY(100%);
        bottom: 0;
        top: unset;
    }
    .kivvi-modal-close {
        height: 100%;
        top: 0;
        display: flex;
        align-items: center;
    }
    .kivvi_section {
        padding: 0;
        margin-block: .5rem;
    }
    .kivvi-modal-inner {
        width: 100%;
        margin-block: 0;
        background-color: var(--kivvi-callout-bar-background-color);
    }
    
    &.active {
        &.show {
            transform: translateY(0);
        
        }
    }
    
}