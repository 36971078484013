:root {
    --kivvi-twoup-modal-video-play-button-width: 50px;
    --kivvi-modal-video-play-button-stroke: black;
    --kivvi-modal-video-play-button-fill: none;
    --kivvi-modal-video-play-button-linecap: round;
    --kivvi-modal-video-play-button-linejoin: round;
    --kivvi-modal-video-link-text-color: black;
    --kivvi-modal-video-link-text-color-light: white;
}

.kivvi-modal-video {
    .kivvi-modal-video-inner {
        position: relative;
        width: 100%;
        .kivvi-modal-video-overlay {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);

            .play-button {
                position: relative;
                width: 100%;
                max-width: 200px;
                min-width: 100px;
                min-height: 100px;
                display: block;
                background: rgba(255, 255, 255, 0.5);
                border-radius: 50%;
                padding: 3rem 3rem 3rem 4rem;
                text-align: center;
                svg {
                    max-width: 200px;
                    min-width: 100px;
                    min-height: 100px;
                }
                svg path {
                    transition: all 0.5s;
                }
                &:hover {
                    svg path {
                        stroke: grey !important;
                    }
                }
            }
            .kivvi-modal-video-link-text,
            .kivvi-modal-video-link {
                text-decoration: none;
                font-size: 1.6rem;
                margin: auto;
                color: var(--kivvi-modal-video-link-text-color);
            }
        }
        &.overlay-text-light {
            .kivvi-modal-video-overlay {
                .kivvi-modal-video-link-text,
                .kivvi-modal-video-link-text a {
                    color: var(--kivvi-modal-video-link-text-color-light);
                }
            }
        }
    }
    button {
        cursor: pointer;
    }
}

.kivvi-modal-video-embed {
    position: fixed;
    opacity: 0;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.9);
    align-items: center;
    justify-content: center;
    display: none;
    z-index: 9999;
    max-height: 100% !important;
    .close-video {
        position: absolute;
        top: var(--space-medium);
        right: var(--space-medium);
        cursor: pointer;
        background: white;
        &:hover {
            background: black;
        }
    }
    &.active {
        display: flex;
        transition: opacity 1s;
        &.show {
            opacity: 1;
        }
    }
}

.kivvi-modal-video-image {
    text-align: center;
}

.kivvi-twoup .kivvi-modal-video {
    .kivvi-modal-video-inner {
        .kivvi-modal-video-overlay {
            width: var(--kivvi-twoup-modal-video-play-button-width);
            left: calc(50% - var(--kivvi-twoup-modal-video-play-button-width));
            .play-button {
                min-width: var(--kivvi-twoup-modal-video-play-button-width);
                min-height: var(--kivvi-twoup-modal-video-play-button-width);
                padding: var(--space-medium) var(--space-medium) var(--space-medium) var(--space-large);
            }
        }
    }
    img {
        width: 100%;
        height: auto;
    }
}
