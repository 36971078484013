:root {
    --loaderColorStart: rgba(35, 229, 219, 0.2);
    --loaderColorMid: rgba(35, 229, 219, 0.5);
    --loaderColorEnd: rgba(35, 229, 219, 0.7);
    --loaderSize: 1rem;
}

#page-transition.fadespinner {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    opacity: 0;
    height: 100%;
    width: 100%;
    pointer-events: none;
    z-index: 0;
    transition: all 2.5s;
    &.active {
        z-index: 9999;
        background-color: black;
        opacity: 1;
        transition: all 1s;
    }
    #page-loader {
        margin: 100px auto;
        font-size: 25px;
        width: var(--loaderSize);
        height: var(--loaderSize);
        border-radius: 50%;
        position: static;
        left: calc(50% - (var(--loaderSize / 2)));
        text-indent: -9999em;
        -webkit-transform: translateZ(0);
        -ms-transform: translateZ(0);
        transform: translateZ(0);
        opacity: 0.5;
        &.entry {
            display: none;
        }
        .circle {
            position: absolute;
            border: 3px solid transparent;
            border-top-color: var(--kivvi-color-orange);
            border-radius: 50%;
            -webkit-animation: rotate linear infinite;
            animation: rotate linear infinite;
        }
        .circle.one {
            height: 50px;
            width: 50px;
            left: 50px;
            top: 50px;
            border-top-color: var(--kivvi-color-teal);
            -webkit-animation-duration: 0.85s;
            animation-duration: 0.85s;
        }
        .circle.two {
            height: 75px;
            width: 75px;
            top: 38px;
            left: 38px;

            -webkit-animation-duration: 0.95s;

            animation-duration: 0.95s;
        }
        .circle.three {
            height: 100px;
            width: 100px;
            top: 25px;
            left: 25px;
            border-top-color: var(--kivvi-color-dark-teal);
            -webkit-animation-duration: 1.05s;
            animation-duration: 1.05s;
        }
    }
}

@keyframes load5 {
    0%,
    100% {
        box-shadow: 0em -2.6em 0em 0em #ffffff,
            1.8em -1.8em 0 0em var(--loaderColorStart),
            2.5em 0em 0 0em var(--loaderColorStart),
            1.75em 1.75em 0 0em var(--loaderColorStart),
            0em 2.5em 0 0em var(--loaderColorStart),
            -1.8em 1.8em 0 0em var(--loaderColorStart),
            -2.6em 0em 0 0em var(--loaderColorMid),
            -1.8em -1.8em 0 0em var(--loaderColorEnd);
    }

    12.5% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorEnd),
            1.8em -1.8em 0 0em #ffffff, 2.5em 0em 0 0em var(--loaderColorStart),
            1.75em 1.75em 0 0em var(--loaderColorStart),
            0em 2.5em 0 0em var(--loaderColorStart),
            -1.8em 1.8em 0 0em var(--loaderColorStart),
            -2.6em 0em 0 0em var(--loaderColorStart),
            -1.8em -1.8em 0 0em var(--loaderColorMid);
    }

    25% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorMid),
            1.8em -1.8em 0 0em var(--loaderColorEnd), 2.5em 0em 0 0em #ffffff,
            1.75em 1.75em 0 0em var(--loaderColorStart),
            0em 2.5em 0 0em var(--loaderColorStart),
            -1.8em 1.8em 0 0em var(--loaderColorStart),
            -2.6em 0em 0 0em var(--loaderColorStart),
            -1.8em -1.8em 0 0em var(--loaderColorStart);
    }

    37.5% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorStart),
            1.8em -1.8em 0 0em var(--loaderColorMid),
            2.5em 0em 0 0em var(--loaderColorEnd), 1.75em 1.75em 0 0em #ffffff,
            0em 2.5em 0 0em var(--loaderColorStart),
            -1.8em 1.8em 0 0em var(--loaderColorStart),
            -2.6em 0em 0 0em var(--loaderColorStart),
            -1.8em -1.8em 0 0em var(--loaderColorStart);
    }

    50% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorStart),
            1.8em -1.8em 0 0em var(--loaderColorStart),
            2.5em 0em 0 0em var(--loaderColorMid),
            1.75em 1.75em 0 0em var(--loaderColorEnd), 0em 2.5em 0 0em #ffffff,
            -1.8em 1.8em 0 0em var(--loaderColorStart),
            -2.6em 0em 0 0em var(--loaderColorStart),
            -1.8em -1.8em 0 0em var(--loaderColorStart);
    }

    62.5% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorStart),
            1.8em -1.8em 0 0em var(--loaderColorStart),
            2.5em 0em 0 0em var(--loaderColorStart),
            1.75em 1.75em 0 0em var(--loaderColorMid),
            0em 2.5em 0 0em var(--loaderColorEnd), -1.8em 1.8em 0 0em #ffffff,
            -2.6em 0em 0 0em var(--loaderColorStart),
            -1.8em -1.8em 0 0em var(--loaderColorStart);
    }

    75% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorStart),
            1.8em -1.8em 0 0em var(--loaderColorStart),
            2.5em 0em 0 0em var(--loaderColorStart),
            1.75em 1.75em 0 0em var(--loaderColorStart),
            0em 2.5em 0 0em var(--loaderColorMid),
            -1.8em 1.8em 0 0em var(--loaderColorEnd), -2.6em 0em 0 0em #ffffff,
            -1.8em -1.8em 0 0em var(--loaderColorStart);
    }

    87.5% {
        box-shadow: 0em -2.6em 0em 0em var(--loaderColorStart),
            1.8em -1.8em 0 0em var(--loaderColorStart),
            2.5em 0em 0 0em var(--loaderColorStart),
            1.75em 1.75em 0 0em var(--loaderColorStart),
            0em 2.5em 0 0em var(--loaderColorStart),
            -1.8em 1.8em 0 0em var(--loaderColorMid),
            -2.6em 0em 0 0em var(--loaderColorEnd), -1.8em -1.8em 0 0em #ffffff;
    }
}
