ul.socials {
    list-style-type: none;
    padding: 0;
    li {
        display: inline-block;
    }
}

.dashicons,
.dashicons-before:before {
    width: 16px;
    height: 16px;
    font-size: 16px;
    margin-right: 4px;
}
