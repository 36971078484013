#site-footer {
    padding: var(--space-default);
    margin: 0;
    .kivvi_section_content {
        display: flex;
        justify-content: space-between;
        flex-direction: row;
        flex-wrap: wrap;
        > * {
            flex: 1;
            min-width: 300px;
        }
    }
}
