.hidden {
    display: none;
}

.screen-reader-text {
    clip: rect(1px, 1px, 1px, 1px);
    height: 1px;
    overflow: hidden;
    position: absolute !important;
    width: 1px;
    word-wrap: normal !important;
}

.visuallyhidden,
.visually-hidden {
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    width: 1px;
}

.centered {
    text-align: center;
    text-wrap: balance;
}

.center-header-text :is(h1, h2, h3, h4) {
    text-align: center !important;
}
