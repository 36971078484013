:root {
    --kivvi-doc-border-color: #cccccc;
    --kivvi-doc-border-color: #43D2B5;
}

.kivvidoc-shortcode, .kivvidoc-component-group {
    padding-bottom: var(--space-medium);
    border-top: 3px solid var(--kivvi-doc-border-color);
}
.kivvidoc-component-group-header, .kivvidoc-shortcode-title {
    text-align: left;
    border-bottom: 1px dashed var(--kivvi-doc-border-color);
}

.single-kivvidoc {
    .kivvi_section_content.page-header {
        display: none;
    }
    details {
        text-align: left;
        
    }
}
ul.kivvidoc-shortcode-container {
    
    padding-left: 0 !important;
    flex-wrap: wrap;
    h3, h4 {
        text-align: left;
    }
    > li {
        list-style: none;
        border: 1px solid var(--kivvi-doc-border-color);   
        padding: var(--space-default) !important;
        margin-block: var(--space-default);
        flex: 1 0 400px;
    }
    .kivvidoc-code {
        opacity: 0;
        max-height: 0;
        transition: all 1s;
        
        &.open {
            opacity: 1;
            max-height: 600px;
            margin-block: var(--space-default);
        }
    }
    pre {
        text-align: left;
        
    }
    code {
        white-space: break-spaces;
    }
}

.kivvidoc-copy, .kivvidoc-code-toggle {
    cursor: pointer;
}

.kivvidoc-code-controls {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    ul {
        padding-left: 0;
        display: flex;
        list-style: none;
        gap: var(--space-small);
        font-size: var(--kivvi-fontsize-l4);
        ;
    }
}

.kivvidoc-menu-link-wrapper {
    margin-bottom: var(--space-medium);
    a {
        
        // text-decoration: none;
    }
}