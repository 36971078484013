:root {
    --animationStartTranslateY: var(--space-medium);
    --animationStartOpacity: 0;
    --animationSpeed: 0.5s;
    --animationDelay: 1s;
    --animationRotation: 35deg;
}

@keyframes fadeOpacity {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

@keyframes fadeOutOpacity {
    0% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

@keyframes rotate {
    // rotate on 3d transform for best perf, it ensures GPU accelerated animation
    from {
        transform: rotateZ(360deg);
    }
    to {
        transform: rotateZ(0deg);
    }
}