.kivvi-modal-gallery {
    
    .kivvi-modal-inner {
        height: 100%;
    }
    .project-gallery {
        display: block;
        position: relative;
        height: 100%;
    }
    .kivvi-gallery-item {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        // display: none;
        opacity: 0;
        padding-block: var(--space-medium);
        &.active-carousel {
            opacity: 1;
        }
        img {
            height: auto;
            width: auto;
            max-width: 80vw;
            max-height: 80vh;
            object-fit: cover;
            margin: auto;
            display: block;
            
        }
    }
    .project-gallery-item-caption {
        text-align: center;
        
    }
    
}