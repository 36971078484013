.h-overflow {
    .kivvi-carousel {
        overflow-x: visible;
    }
    .kivvi-carousel-item {
        opacity: 1;
        overflow-x: visible;
        position: relative;
    }
}

.h-overflow,
.h-overflow div:is(.blogroll, .kivvi-cardset-items, .kivvi-image-gallery-items) {
    transition: all 1s;
}
@media screen and (min-width: 849px) {
    .h-overflow:is(.kivvi-blogroll, .kivvi-cardset)
        div:is(.blogroll, .kivvi-cardset-items, .kivvi-image-gallery-items) {
        flex-wrap: nowrap;
    }
}

.h-overflow-controls {
    margin: auto;
    display: flex;
    justify-content: center;
    gap: 5px;
}

.h-overflow-section {
    overflow-x: hidden;
}
