:root {
    --kivvi-card--max-width: var(--kivvi-single-item-width-max);
    --kivvi-card--font-size: 28px;
}

.kivvi-cardset {
    position: relative;
}

.kivvi-cardset-items {
    display: grid;
    flex-wrap: wrap;
    margin-top: var(--space-medium);
    gap: var(--space-medium);
    grid-template-columns: 1fr 1fr 1fr;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .kivvi-card {
        flex: 1;
        min-width: var(--kivvi-single-item-width);
        max-width: var(--kivvi-card--max-width);
    }
    a:not(.button) {
        text-decoration: none;
        color: inherit;
    }
}
.kivvi-card-wrapper {
    height: 100%;
    width: 100%;
}
.kivvi-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-self: center;
    max-width: var(--kivvi-card--max-width);
    word-break: normal;
    .imgwrapper {
        width: 100%;
    }
    img {
        width: 100%;
        object-fit: cover;
    }
    h3 {
        font-weight: bold;
        font-size: var(--kivvi-card--font-size);
    }
}
