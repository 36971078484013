.timeline {
    max-width: 800px;
    margin: var(--space-medium) auto;
}

.timeline li {
    position: relative;
    list-style-type: none;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 50px 80px;
}
.timeline li:before {
    content: "";
    position: absolute;
    left: 0;
    top: -8px;
    width: 100%;
    height: 100%;
    opacity: 0;
    background-image: url("/wp-content/themes/kivvi/images/circle.png");
    background-repeat: no-repeat;
    z-index: 100;
    pointer-events: none;

    opacity: 1;
}
.timeline li:after {
    background-image: url("/wp-content/themes/kivvi/images/borderline.png");
    background-size: 1px calc(100% - 27px);
    background-repeat: no-repeat;
    content: "";
    position: absolute;
    left: 22px;
    top: 27px;
    width: 100%;
    height: 100%;
    z-index: 0;
    pointer-events: none;
    transition: all 2s;
    max-height: 0;
    opacity: 0;

    opacity: 1;
    max-height: 1000px;
}
.timeline li:last-of-type:after {
    background: none;
}

.timeline.active {
    li {
        &:before {
            transition: all 1s;
            opacity: 1;
        }
        &:nth-of-type(2) {
            &:before {
                transition-delay: 1s;
            }
        }
        &:nth-of-type(3) {
            &:before {
                transition-delay: 2s;
            }
        }
        &:nth-of-type(4) {
            &:before {
                transition-delay: 3s;
            }
        }
        &:nth-of-type(5) {
            &:before {
                transition-delay: 4s;
            }
        }
        &:nth-of-type(6) {
            &:before {
                transition-delay: 5s;
            }
        }
        &:nth-of-type(7) {
            &:before {
                transition-delay: 6s;
            }
        }
        &:nth-of-type(8) {
            &:before {
                transition-delay: 7s;
            }
        }
    }
    li {
        &:after {
            transition: all 1s;
            max-height: 1000px;
            opacity: 1;
            transition-delay: 0.5s;
        }
        &:nth-of-type(2) {
            &:after {
                transition-delay: 1.5s;
            }
        }
        &:nth-of-type(3) {
            &:after {
                transition-delay: 2.5s;
            }
        }
        &:nth-of-type(4) {
            &:after {
                transition-delay: 3.5s;
            }
        }
        &:nth-of-type(5) {
            &:after {
                transition-delay: 4.5s;
            }
        }
        &:nth-of-type(6) {
            &:after {
                transition-delay: 5.5s;
            }
        }
        &:nth-of-type(7) {
            &:after {
                transition-delay: 6.5s;
            }
        }
        &:nth-of-type(8) {
            &:after {
                transition-delay: 7.5s;
            }
        }
    }
}
