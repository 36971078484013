.kivvi-modal {
    --kivvi-modal-transition-length: 1s;
    --kivvi-modal-transition-delay: 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    display: none;
    align-items: center;
    justify-content: center;  
    opacity: 0;
    transition-property: opacity;
    transition-duration: var(--kivvi-modal-transition-length);
    transition-delay: var(--kivvi-modal-transition-delay);
    .kivvi-modal-inner {
        width: 90%;
        min-width: var(--kivvi-single-item-width);
        margin: auto;
        background: white;
        position: relative;
        container-name: kivvi-modal-inner;
        container-type: inline-size;
    }
    .kivvi-modal-close {
        position: absolute;
        top: var(--space-default);
        right: var(--space-default);
        cursor: pointer;
        z-index: 10;
    }
    &.active {
        display: flex;
        &.show {
            --kivvi-modal-transition-delay: 0;
            opacity: 1;
        }
    }    
}

/* PREVIOUS CODE - MAY NOT BE IN USE BUT WOULD NEED TO RESEARCH BEFORE CONFIRMING AND REMOVING */
.modal.slidein {
    transform: translateX(150%);
    transition: transform 0.5s ease-in-out;
    background: white;
    position: fixed;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: auto;
    color: black;
    z-index: 500;
    display: flex;
    line-height: 1.5;
    text-align: left;
    .modal-text {
        padding-top: 2rem;
        width: 66%;
        h2,
        h3,
        p {
            width: 90%;
            max-width: var(--kivvi-single-item-width-max);
            margin: var(--space-default) auto;
        }
    }
    .modal-img {
        width: 34%;
        img {
            height: 100vh;
            width: 100%;
            object-fit: cover;
            max-height: 100vh;
        }
    }
    h2 {
        color: var(--kivvi-color-dark-teal);
        text-transform: uppercase;
        font-size: 32px;
    }
    h3 {
        color: var(--kivvi-color-orange);
    }
    .blurb {
        color: var(--kivvi-color-dark-teal);
    }
    p {
        font-size: 16px;
    }
    &.active {
        transform: translateX(0);
    }
    .modal-close {
        position: absolute;
        right: 20px;
        top: 0;
        font-size: 50px;
        color: var(--kivvi-color-teal);
        transition: transform 0.3s;
        cursor: pointer;
        &:hover {
            transform: rotate(90deg);
        }
    }
}
