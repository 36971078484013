$kivvi-max-content-width: 1280px;

:root {
    --kivvi-base-font-family: Helvetica, sans-serif;
    --kivvi-h1-font-family: var(--kivvi-base-font-family);
    --kivvi-h2-font-family: var(--kivvi-base-font-family);
    --kivvi-h3-font-family: var(--kivvi-base-font-family);
    --kivvi-h4-font-family: var(--kivvi-base-font-family);
    --kivvi-h5-font-family: var(--kivvi-base-font-family);
    --kivvi-h6-font-family: var(--kivvi-base-font-family);

    --kivvi-base-font-color: #666;
    --kivvi-h1-font-color: var(--kivvi-base-font-color);
    --kivvi-h2-font-color: var(--kivvi-base-font-color);
    --kivvi-h3-font-color: var(--kivvi-base-font-color);
    --kivvi-h4-font-color: var(--kivvi-base-font-color);
    --kivvi-h5-font-color: var(--kivvi-base-font-color);
    --kivvi-h6-font-color: var(--kivvi-base-font-color);

    --kivvi-base-text-align: left;
    --kivvi-header-text-align: center;
    --kivvi-h1-text-align: var(--kivvi-header-text-align);
    --kivvi-h2-text-align: var(--kivvi-header-text-align);
    --kivvi-h3-text-align: var(--kivvi-header-text-align);
    --kivvi-h4-text-align: var(--kivvi-header-text-align);
    --kivvi-h5-text-align: var(--kivvi-header-text-align);
    --kivvi-h6-text-align: var(--kivvi-header-text-align);

    --kivvi-link-color: rgb(0, 0, 238);
    --kivvi-link-hover-color: var(--kivvi-link-color);
    --kivvi-link-active-color: var(--kivvi-link-color);
    --kivvi-link-visited-color: var(--kivvi-link-color);
    --kivvi-link-transition-time: 0.3s;
    --kivvi-link-transition: all var(--kivvi-link-transition-time) ease-in-out;
    --kivvi-link-decoration: none;
    --kivvi-link-hover-decoration: none;
    --kivvi-link-underline-offset: 5px;

    --kivvi-max-content-width: #{$kivvi-max-content-width}; // Default to 1280px

    --kivvi-full-width-margin-right: calc(-100vw / 2 + var(--kivvi-max-content-width) / 2);
    --kivvi-full-width-padding-inline: calc((-100vw / 2 + var(--kivvi-max-content-width) / 2) * -1);
    --kivvi-full-width-padding-left: calc((-100vw + var(--kivvi-max-content-width)) / 2 * -1);
    --kivvi-full-width-margin-left: calc((-100vw + var(--kivvi-max-content-width)) / 2);
}

* {
    font-family: var(--kivvi-base-font-family);
}

html {
    background: var(--kivvi-global-background);
    color: var(--kivvi-base-font-color);
    scroll-behavior: smooth;
}

body {
    margin: 0;
    font-size: var(--kivvi-base-font-size);
}

p {
    &:empty {
        display: none;
    }

    margin-block: var(--space-default);
}

a:not(.button) {
    color: var(--kivvi-link-color);
    transition: var(--kivvi-link-transition);
    text-decoration: var(--kivvi-link-decoration);
    text-underline-offset: var(--kivvi-link-underline-offset);
    &:visited {
        color: var(--kivvi-link-visited-color);
    }
    &:hover {
        color: var(--kivvi-link-hover-color);
        text-decoration: var(--kivvi-link-hover-decoration);
    }
    &:active {
        color: var(--kivvi-link-active-color);
    }
}

.kivvi_section {
    background-size: cover;
    text-align: center;
    padding: var(--space-default) 0;
    margin: var(--space-larger) auto;
    position: relative;
    p {
        text-align: var(--kivvi-base-text-align);
    }
    li {
        text-align: left;
    }
    @media screen and (min-width: 1280px) and (max-width: 1320px) {
        padding-left: 20px;
        padding-right: 20px;
    }
    &.flex {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    @media screen and (max-width: 1000px) {
        margin-block: var(--space-default);
    }
}
.kivvi_section_content {
    position: relative;
    max-width: var(--kivvi-max-content-width);
    margin: auto;
    width: 90%;

    display: flex;
    flex-direction: column;
    gap: var(--space-larger);
}

.page-template-default .kivvi_section {
    text-align: var(--kivvi-base-text-align);
}

.full-width {
    @media screen and (min-width: $kivvi-max-content-width) {
        /* THIS WAS IN USE ON ORIGINAL SITE BUILD, WHEN IMAGES NEEDED TO SPAN THE FULL-WIDTH. THINGS HAVE BEEN REENGINEERED AND IT DOESN'T APPEAR NEEDED.
            LEAVING IN PLACE BECAUSE IT'S HELPFUL WITH SOME LAYOUTS

        margin-left: var(--kivvi-full-width-margin-left);

        margin-right: 0;
        padding-left: var(--kivvi-full-width-padding-left);
        padding-right: 0;

        */

        width: 100%;

        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;

        &.sub-full-width {
            max-width: none !important;
            width: calc(100vw - var(--kivvi-full-width-padding-left));
        }
    }
    @media screen and (max-width: 950px) {
        &:not(.kivvi-section-background-keep-image-mobile) {
            background-image: none !important;
        }

        padding-bottom: 0;
    }
    padding-top: 0;

    background-repeat: no-repeat;
    background-position: top right;
    position: relative;

    /* 
    ** FROM BIM
    background-size: contain;
    background-size: contain;
    */
}

.min-margin-bottom {
    margin-bottom: var(--space-default);
}
.section {
    &.min-margin-bottom {
        margin-bottom: var(--space-default);
    }
    &.no-margin-bottom {
        margin-bottom: 0;
    }
}
.extra-padding {
    padding-top: calc(var(--space-huge) * 3);
}

.no-margin-top {
    margin-top: 0;
}

.width-950 {
    max-width: 950px;
    @media screen and (min-width: 1000px) {
        &.white-section .kivvi_section_content {
            padding-left: 0;
            padding-right: 0;
        }
    }
}
