:root {
    --header-height: 192px;
}

#masthead {
    max-width: var(--kivvi-max-content-width);
    margin: auto;
    width: 90%;
}

.sticky {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 9000;
}
.admin-bar .sticky {
    --bim-admin-bar-height: 32px;
    top: var(--bim-admin-bar-height);
}
body:not(.overlapping-header) .sticky + #page {
    padding-top: 102px;
}

#site-navigation #menu-main-menu {
    display: flex;
    gap: var(--space-default);
    flex-wrap: wrap;
    list-style: none;
}

.kivvi-logo {
    padding-block: var(--space-default);
}

.overlapping-header {
    #header-section {
        background-color: transparent;
        width: 100%;
        position: absolute;
        top: 0;
        z-index: 9000;
        &.sticky-header {
            position: fixed;
        }
    }
    #header-section.sticky {
        position: fixed;
    }

    #content > *:first-child {
        margin-block-start: 0;
        .kivvi_section_content {
            &:before {
                content: "";

                height: calc(var(--header-height) - var(--space-larger));
            }
        }
    }
}
