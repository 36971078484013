.kivvi-blogroll {
    position: relative;
    &.h-overflow {
        display: flex;
        @media screen and (max-width: 850px) {
            display: block;
        }
        @media screen and (min-width: 851px) {
            .kivvi-blogroll-container {
                width: 200vw;
                overflow-x: hidden;
                z-index: 1;
                margin-right: -100vw;
            }
            .blogroll {
                justify-content: flex-start;

                .blog-item {
                    max-width: var(--kivvi-single-item-width);
                }
            }
            .kivvi-header-text {
                max-width: 400px;
            }
        }

        .kivvi-header-text {
            min-width: 400px;
            width: 400px;
            max-width: 100%;
            padding-bottom: 4rem;
            z-index: 5;
        }
    }
    .kivvi-header-text {
        text-align: center;
    }
    .blogroll {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        gap: var(--space-medium);

        .blog-item {
            flex: 1;
            min-width: var(--kivvi-single-item-width);
            max-width: var(--kivvi-single-item-width-max);
            @media screen and (max-width: 400px) {
                min-width: 100%;
                max-width: 100%;
            }
        }
    }
}

.kivvi-blogroll {
    text-align: left;
    margin-bottom: 2rem;
    h2,
    h3 {
        text-align: center;
    }

    p {
        line-height: 1.3;
        margin: 10px 0;
    }
    .blogroll {
        transition: opacity 0.5s;
    }
    a:not(.button) {
        text-decoration: none;
        color: inherit;
    }

    img {
        height: 300px;
        height: auto;
        aspect-ratio: 1.5 / 1;
        // background: white;
        object-fit: contain;
        object-position: center;
        &.fill {
            object-fit: cover;
            object-position: top center;
        }
    }
}
.kivvi-blogroll-button {
    display: flex;
    justify-content: center;
    margin-top: var(--space-default);
}

.kivvi-pagination {
    display: flex;
    justify-content: center;
    margin: var(--space-medium) 0;
    gap: var(--space-small);
    --kivvi-pagination-border-color: var(--kivvi-button-border-color);
    .page-numbers {
        padding: 0.5rem 1rem;
        border: 1px solid var(--kivvi-pagination-border-color);
        border-radius: 8px;
        &.current {
            border: unset;
        }
    }
}

.kivvi-blogroll.layout-carousel {
    .kivvi-carousel {
        display: block;
        .blog-item {
            max-width: unset;
            min-width: none;
        }
    }
}

.kivvi-blogroll-loading {
    display: grid;
    place-items: center;
    width: 100%;
}

.kivvi-category-list {
    margin: auto;
    margin-bottom: 2rem;
    position: relative;
    ul {
        position: relative;
        list-style: none;
        display: flex;
        margin: auto;
        justify-content: center;
        position: relative;
        width: fit-content;
        padding-left: 0;
    }
    li {
        padding: 0.75rem 1.5rem;
        border-right: 1px solid black;
        transition: all 0.5s;
    }

    .kivvi-blogroll-category-search-box {
        position: absolute;
        top: 0;
        right: 0;

        width: 100%;

        height: 100%;
        opacity: 0;
        pointer-events: none;
        transition: all 0.5s;
        // transition-delay: 0.5s;
        form {
            display: flex;
            gap: var(--space-medium);
            justify-content: center;
        }
    }
    .kivvi-category-search-close {
        align-self: center;
    }
    ul.search-active {
        li {
            opacity: 0;
        }
        .kivvi-blogroll-category-search-box {
            opacity: 1;
            pointer-events: all;
            // transform: translateX(0);
        }
    }
}
